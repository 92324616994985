<script setup>
import {route} from "ziggy-js";
import {__} from "@/Utils/index.js";

defineProps({
    title: {
        type: String,
        default: __('or signup using')
    }
});

const hasGoogle = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const hasFacebook = import.meta.env.VITE_FACEBOOK_CLIENT_ID;

const hasExternal = hasGoogle || hasFacebook;

</script>

<template>
    <div v-if="hasExternal">
        <div class="relative">
            <div class="relative flex justify-center text-base font-medium leading-6">
                <p
                    dusk="socialite-title"
                    class="dark:bg-slate-800 px-6 text-gray-400 dark:text-gray-400">{{ title }}</p>
            </div>
        </div>

        <div class="mt-6 grid grid-cols-2 gap-4">
            <a
                v-if="hasFacebook"
                dusk="socialite-facebook-link"
                :href="route('auth.facebook.redirect')"
                class="flex w-full items-center justify-center gap-3 rounded-full bg-white dark:bg-slate-800 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-slate-200 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-400 hover:dark:ring-slate-200 hover:bg-gray-50 dark:hover:bg-slate-700 focus-visible:ring-transparent">
                <img src="/social/facebook.png" alt="Google Login" class="size-5">
            </a>
            <a
                v-if="hasGoogle"
                dusk="socialite-google-link"
                :href="route('auth.google.redirect')"
                class="flex w-full items-center justify-center gap-3 rounded-full bg-white dark:bg-slate-800 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-slate-200 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-400 hover:dark:ring-slate-200 hover:bg-gray-50 dark:hover:bg-slate-700 focus-visible:ring-transparent">
                <img src="/social/google.png" alt="Google Login" class="size-5">
            </a>
        </div>
    </div>

</template>
